window.setParameterByName = (type, value) => {
  let url = new URL(window.location.href);
  url.searchParams.set(type, value)

  window.history.pushState({}, null, url);
}

window.queryParamEvents = (eventType, query, value = "") => {
  const url = window.location.search;
  const params = new URLSearchParams(url);

  switch (eventType) {
    case "get":
      return params.get(query);
    case "set":
      params.set(query, value);
      return window.history.pushState({}, null, `?${params}`);
    case "delete":
      params.delete(query);
      const changedUrl = params.toString().length ? `?${params}` : "?";

      return window.history.pushState({}, null, changedUrl);
    default:
      return console.log(`There is no type - ${eventType}`);
  }
};
